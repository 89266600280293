import { mapState, mapActions } from 'vuex'
import Vue from 'vue'
import { Empty, Toast, Dialog, Loading, Popup, Collapse, CollapseItem ,Button} from 'vant'
import { setTimeout } from 'core-js'

Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Popup)
Vue.use(Loading)
Vue.use(Dialog)
Vue.use(Empty)
Vue.use(Button);
Vue.use(Toast)
export default {
  components: {},
  data () {
    return {
      problem: '',
      disable: true,
      length: '0',
      name: '',
      isloading:false,
      id:''
    }
  },
  created () {
    this.id = this.$route.query.id
    this.name = this.$route.query.name
  },
  updated () {
    this.name = this.$route.query.name
    this.length = this.problem.length
    if (this.problem == '') {
      this.disable = true
    } else {
      this.disable = false
    }
  },
  methods: {
    ...mapActions('equityCenter', ['usageEquity']),
    back () {
      this.$router.go(-1)

    },
    toSub () {
      this.isloading=true
      var obj = JSON.stringify({
        illnessNote: this.problem,
        yhUserEquityId: this.$route.query.id

      })

      this.usageEquity(obj).then(res => {
        this.isloading=false
        if (res.code == 200) {
          this.$router.push({ name: 'message', query: { name: this.name, fId: res.flowId,theid:this.id } })
          console.log(res.flowId)
        } else {
          Toast(res.msg)
        }
      })



    }

  },
}
